.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.inputArea {
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.textarea {
  width: 100%;
  height: 100px;
  resize: none;
}

.dropdown {
  width: 100px;
}

.audioBar {
  width: 100%;
}

.audioControl {
  width: 100%;
}

.createButton {
  align-self: flex-end;
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}
